import * as React from "react";
import { withStores } from "src/stores";
import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";
import { observer } from "mobx-react";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

/**
 * Used to wrap Shared UI or Bex API components relevant to Lodging Destination (components in the @shared-ui/lodging-property-search package).
 * If you use the additionalInfo props, make sure that all the components using the same query are in sync and have the
 * same variables, else duplicate queries will be sent to the downstreams services.
 *
 * Based on the PropertyBexApiWrapper and TripsBexApiWrapper files.
 * See PropertyReviewsWrapper or PropertyReviewsHeadline for more examples.
 * @param Component
 * @param options
 * @constructor
 */

export interface LodgingDestinationBexApiWrapperProps<I> {
  propertyFilters: PropertyFiltersStore;
  context: ExtendedContextStore;
  additionalInputs: I;
}

export const LodgingDestinationBexApiWrapper = <I, P extends object>(Component: React.FC<{ inputs: I } & P>) =>
  withStores("propertyFilters")(
    observer(
      ({
        context,
        additionalInputs,
        propertyFilters,
        ...props
      }: LodgingDestinationBexApiWrapperProps<Omit<I, "destination" | "filters">> & Omit<P, "inputs">) => {
        if (!context) return null;

        const destination = {
          regionId: String(context.searchContext.location.id),
        };

        const filters = propertyFilters.getFiltersInput() || {};

        const inputs = {
          destination,
          filters,
          ...additionalInputs,
        } as I;

        return <Component inputs={inputs} {...(props as P)} />;
      }
    )
  );
