import * as React from "react";

import { DatedSearchesFlexModuleResult, DatedSearchesProps } from "./typings";

import { LodgingDestinationBexApiWrapper } from "src/components/shared/BexApiWrapper/LodgingDestinationBexApiWrapper";
import { DatedSearches as SharedUiDatedSearches } from "@shared-ui/lodging-property-search";
import { UitkSpacing } from "@egds/react-core/spacing";
import { withStores } from "src/stores";

const DEFAULT_DATATYPE = "DATES";
const DEFAULT_VIEW = "LIST";

export const DatedSearches = LodgingDestinationBexApiWrapper(SharedUiDatedSearches);

export const DatedSearchesWrapper: React.FC<DatedSearchesProps> = ({
  flexModuleModelStore,
  context,
  templateComponent,
}) => {
  if (!flexModuleModelStore || !templateComponent) {
    return null;
  }

  const {
    metadata: { id },
    config: { view },
  } = templateComponent;
  const model = (flexModuleModelStore.getModel(id) as DatedSearchesFlexModuleResult) || null;

  if (!model) {
    return null;
  }

  const additionalInputs = {
    pageType: context?.searchContext?.pageType || "",
    dataType: model.dataType || DEFAULT_DATATYPE,
  };

  return (
    <UitkSpacing
      margin={
        view === "LANDING"
          ? undefined
          : { small: { inline: "two", blockstart: "three", blockend: "six" }, large: "three" }
      }
    >
      <DatedSearches
        context={context}
        additionalInputs={additionalInputs}
        view={view || DEFAULT_VIEW}
        desktopView={model.displaySize}
      />
    </UitkSpacing>
  );
};

export default withStores("flexModuleModelStore", "context")(DatedSearchesWrapper);
